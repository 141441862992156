<template>
    <div class="cmp-project-list" :class="{ 'saving-project' : this.savingProject}">
        
        <template v-if="showFullScreenLoader">
            <div class="full-screen-fader flex-column justify-content-center progress">
                <div class="loader-wrapper">
                    <span class="loader"></span>  
                    <span v-translation="{ code: 'label_adding_products', type: 'label'}" ></span>
                </div
            ></div>
        </template>

        <transition name="fadein">
            <utlz-edit-project-list 
                v-if="showEditProject" 
                :project="projectToEdit"
                @closeEditProject="showEditProject = false"
                @saveProject="saveProject($event)"
                @deleteProject="setDeleteProjectPrompt($event)"
                ></utlz-edit-project-list>
        </transition>

        <div class="top-bar" v-if="projects.length > 0">
            <div class="col-sm-6">
                <div class="select-wrapper">
                    <span class="fa fa-angle-down select-angle"></span>
                    <select @change="searchForProjects({ searchType: 'select' })" v-model="selectedProjectCode" v-if="projectsToSelect.length">
                        <option value="" selected="selected">{{ selectProjectPlaceholder }}</option>
                        <option :value="project.ProjectCode" v-for="project in projectsToSelect" :key="project.ProjectCode">{{ project.ProjectDescription }}</option>
                    </select>
                </div>
                </div>
                <div class="col-sm-6">
                    <div class="project-selection">
                        <input type="text" :placeholder="searchProjectPlaceholder" v-model="searchText" @input="searchForProjects({ searchType: 'query' })" />
                    </div>         
                </div>
            
        </div>
        <div class="new-proj-row">
            <a 
                @click="editProjectList({ newProject: true })"
                v-translation="{ code: 'button_add_project', type: 'button'}" 
                class="btn-u">
            </a>
        </div>
        
        <transition name="fadein">
            <div class="cmp-prompt" v-if="projectInShoppingCartPrompt">
                <div class="full-screen-fader flex-column justify-content-center">
                    <div class="utlz-container">
                    <div class="utlz-row">
                        <div class="utlz-col-lg-8 utlz-offset-lg-2">
                        <span class="close-button">
                            <i class="fa fa-times-circle" aria-hidden="true" @click="hidePrompt()"></i>
                        </span>
                        <div class="prompt-content">
                            <h2>
                                <span v-translation="{ type: 'title', code: 'title_project_in_shopping' }"></span>
                            </h2>
                            <div>
                                <span v-translation="{type: 'text', code: 'text_project_in_shopping'}"></span>
                            </div>
                            <div class="action-btns full-width-buttons-mobile">
                                <a @click="emptyCart()" v-translation="{ type: 'button', code: 'button_clear_shopping'}" class="button cancel"></a>
                                <a @click="storeOrderInConceptOrders()" v-translation="{ type: 'button', code: 'button_save_concept'}" class="button theme-primary confirm"></a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="fadein">
            <div class="cmp-prompt" v-if="showDeleteProjectPrompt">
                <div class="full-screen-fader flex-column justify-content-center">
                    <div class="utlz-container">
                    <div class="utlz-row">
                        <div class="utlz-col-lg-8 utlz-offset-lg-2">
                        <span class="close-button">
                            <i class="fa fa-times-circle" aria-hidden="true" @click="hideDeleteProjectPrompt()"></i>
                        </span>
                        <div class="prompt-content">
                            <h2>
                                {{ projectForDeletion.ProjectDescription }}
                            </h2>
                            <div>
                                <span v-translation="{type: 'text', code: 'text_prompt_remove'}"></span>
                            </div>
                            <div class="action-btns full-width-buttons-mobile">
                                <a @click="hideDeleteProjectPrompt()" v-translation="{ type: 'button', code: 'button_cancel'}" class="button cancel"></a>
                                <a @click="deleteProject()" v-translation="{ type: 'button', code: 'button_delete'}" class="button theme-primary confirm"></a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </transition>
        
        <div class="project-list" v-if="projectSource.length > 0">
            <div class="project" :key="project.ProjectCode" 
                v-for="project in projectSource">
                <div class="project-line">
                    <div class="left-align" @click="setProjectVisibility(project)">
                        <span class="fa" :class="openProjectSource.includes(project.ProjectCode) ? 'fa-angle-up' : 'fa-angle-down'"></span>
                        <h2 class="project-desc">{{project.ProjectDescription}}</h2>
                    </div>
                    <div class="right-align">

                        <a
                            v-translation="{ code: 'button_edit_project', type: 'button'}" 
                            @click="editProjectList({ newProject: false, project: project })" 
                            class="btn-u btn-grey">
                        </a>
                        <!-- <a @click="setDeleteProjectPrompt(project)" v-translation="{ type: 'label', code: 'label_delete' }"></a>   -->
                        <a class="btn-u blue-btn" 
                        v-translation="{ type: 'label', code: 'label_order' }" 
                        v-on:click="orderProject(project)"
                        v-if="openProjectSource.includes(project.ProjectCode)"></a>                              
                        
                    </div>                    
                </div>
                <div class="locations" v-if="openProjectSource.includes(project.ProjectCode)">
                    <div class="location" 
                        :key="location.LocationId" 
                        v-for="(location, index) in project.Locations">
                        <div class="location-top-bar">
                            <h3 class="desc">{{location.LocationDescription}}</h3>
                            <template v-if="index == 0">
                                <span class="quantity" v-translation="{ type: 'label', code: 'label_amount' }"></span>
                                <span class="price" v-translation="{ type: 'label', code: 'label_price' }"></span>
                                <span class="total" v-translation="{ type: 'label', code: 'label_row_amt' }"></span>
                            </template>
                        </div>
                        
                        <div class="location-products" v-if="project.productInformation">
                            <div class="product" :key="product.id" v-for="product in location.Products">
                                <div class="prod-img">
                                    <img :src="getProdImage(getSpecificProductInformation(project.ProjectCode, product.Id))" />
                                </div>
                                <div class="desc">
                                    <span>{{ getSpecificProductInformation(project.ProjectCode, product.Id).shortDescription }}</span>
                                    <p class="prod-code">
                                        {{ product.Id }}
                                    </p>
                                    <p class="order-comment-value">{{ product.Comment }}</p>
                                </div>                                
                                <div class="quantity">
                                    <template v-if="showActions(product)">
                                        <utlz-quantity-buttons
                                            :initialQuantity="product.Quantity"
                                            :allowZeroQuantity="true"
                                            :quantityFactor="1"
                                            @quantityChanged="quantityChanged($event, location, product)">
                                        </utlz-quantity-buttons>
                                    </template>
                                    <template v-else>
                                      <span>{{ product.Quantity }}</span>
                                    </template>
                                    
                                </div>
                                <span class="price">
                                    <span class="mob-label price" v-translation="{ type: 'label', code: 'label_price' }"></span>
                                    {{getProductPrice(getSpecificProductInformation(project.ProjectCode, product.Id))}}</span>
                                <span class="total">                                    
                                    <span class="mob-label total" v-translation="{ type: 'label', code: 'label_row_amt' }"></span>
                                    {{getProductPriceTotal(getSpecificProductInformation(project.ProjectCode, product.Id), product.Quantity)}}</span>                                
                            </div>
                        </div>
                    </div>
                </div>                
            </div>            
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
import EditProjectList from './../project-list/EditProjectList.vue'
export default {
    components: {
      'utlz-quantity-buttons': QuantityButtons,
      'utlz-edit-project-list': EditProjectList
    },
    data() {
        return {
            selectedProjects: [],
            searchText: '',
            openProjects: [],
            projectSelectedForOrder: {},
            projectInShoppingCartPrompt: false,
            showDeleteProjectPrompt: false,
            projectForDeletion: {},
            projectToEdit: {},
            showEditProject: false,
            savingProject: false,
            selectProjectPlaceholder: window.vue.translations.label.label_select_project,
            searchProjectPlaceholder: window.vue.translations.label.label_search_project,
            selectedProjectCode: "",
            phOrderComment: window.vue.translations.label.label_orderline_comment_ph,
            showFullScreenLoader: false
        }
    },
    computed: {
        ...mapGetters('projectList', ['projects']),
        ...mapGetters(['bespokeGlobalWs', 'bespokeEndpoints']),
        ...mapGetters('shoppingCart', ['orderLines']),        
        sortElements(elements) {
            return elements.sort((a, b) => a.RowOrder - b.RowOrder);
        },
        projectSource() {
            return this.searchText !== '' || this.selectedProjectCode !== '' ? this.selectedProjects : this.projects;
        },
        openProjectSource() {
            return this.openProjects;
        },
        projectsToSelect() {
            return this.projects;
        }
    },
    methods: {      
        ...mapActions('shoppingCart', ['addToCart', 'getOrderLines', 'emptyShoppingCartPromise', 'getOrderLinesPromise']),
        ...mapActions('projectList', ['getProductInformation', 'createNewCart', 'deleteProductFromDb', 'deleteProjectFromDb', 'getProjects']),
        showActions(product) {
            return !product.isRepresentativeProduct || (product.isRepresentativeProduct && this.bespokeGlobalWs.isRepresentative)
        },
        searchForProjects({ searchType }) {
            if (searchType === 'query') {
                this.selectedProjectCode = '';
            }
            if (searchType === 'select') {
                this.searchText = '';
            }

            if (this.searchText !== '') {
                var query = this.searchText;
                this.selectedProjects = this.projects.filter(function(project) {
                return project.ProjectDescription.toLowerCase().includes(query.toLowerCase());
            });
            } else if (this.selectedProject !== ''){
                this.selectedProjects = this.projects.filter(project => project.ProjectCode === this.selectedProjectCode);
            } else {
                selectedProjects = null;
            }
        },
        setProjectVisibility(project) {
            let projectCode = project.ProjectCode.toString();
            if (this.openProjects.includes(projectCode)) {
                this.openProjects = this.openProjects.filter((x) => x !== projectCode);
            } else {
                this.openProjects.push(projectCode);           
                this.getProductInformation(project);
            }
        },
        getSpecificProductInformation(projectId, productId) {
            let project = this.projects.filter(x => x.ProjectCode === projectId)[0];

            if (!project.productInformation) {
                return {};
            } 
            return project.productInformation.filter((x) => x.id === productId)[0];
        },
        getProductPrice(prod){
            if (!prod) {
                return 0;
            }
            if (!prod.prices[0]){
                return 0;
            }
            return prod.prices[0].price;
        },
        getProdImage(prod) {
            if (!prod) {
                return 0;
            }
            return prod.imageUrl ? prod.imageUrl : '/documents/productimages/not-available-small.jpg';
        },
        getProductPriceTotal(prod, quantity){
            if (!prod) {
                return 0;
            }
            if (!prod.prices[0]){
                return 0;
            }
            let price = prod.prices[0].rawPrice * quantity;            
            return prod.prices[0].price.substring(0,1) + (Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2).toString().replace('.', ',');
        },
        quantityChanged({ quantity }, location, product) {
            location.Products.filter(x => x.DbId === product.DbId)[0].Quantity = quantity; 
        },
        orderProject(project) {
            this.getOrderLinesPromise().then((x) => {
                var projectLine = this.orderLines.filter(x =>  { 
                    if (x.customFields != undefined) {
                        return x.customFields.project !== undefined;
                    }
                 })[0];

                if (!projectLine) {
                    this.doOrder(project);
                } else {
                    this.projectSelectedForOrder = project;
                    this.projectInShoppingCartPrompt = true;
                }
            });            
        },
        doOrder(project) {
            this.showFullScreenLoader = true;
            let payload = [];
            project.Locations.forEach(x => {
                x.Products.forEach(prod => {
                    if (prod.Quantity > 0) {
                        payload.push(this.getOrderPayload(prod, project, x));
                    }
                });
            });

            this.addToCart({ payload: payload, projectName: project.ProjectDescription }).then(_ => {
                this.showFullScreenLoader = false;
            });         
        },
        emptyCart() {
            let proj = this.projectSelectedForOrder;            
            this.emptyShoppingCartPromise().then(x=> this.doOrder(proj));
            this.hidePrompt();
        },
        storeOrderInConceptOrders() {
            this.createNewCart();
        },
        hidePrompt() {
            this.projectSelectedForOrder = {};
            this.projectInShoppingCartPrompt = false;
        },
        hideDeleteProjectPrompt() {
            this.showDeleteProjectPrompt = false;
            this.projectForDeletion = {};
        },
        getOrderPayload(product, project, location) {
            return {
                prod_code: product.Id,
                ord_qty: product.Quantity,
                unit_code: '0',
                customFields: {
                    project: project.ProjectCode,
                    projectDesc: project.ProjectDescription,
                    locationCode: location.LocationCode,
                    locationDescription: location.LocationDescription
                },
                prod_comment: product.Comment ? product.Comment : ""
            }
        },
        deleteProduct(location, product, project) {
            let prodIndex = location.Products.findIndex(x => x.Id === product.Id);
            location.Products.splice(prodIndex,1);

            if (location.Products.length === 0) {
                let locationIndex = project.Locations.findIndex(x => x.LocationCode === location.LocationCode);
                project.Locations.splice(locationIndex,1);
            }

            this.deleteProductFromDb(product.DbId);
        },
        setDeleteProjectPrompt(project) {            
            this.projectForDeletion = project;
            this.showDeleteProjectPrompt = true;
        },
        deleteProject(){            
            var project = this.projectForDeletion;
            let projectIndex = this.projects.findIndex(x => x.ProjectCode === project.ProjectCode);
            this.projects.splice(projectIndex, 1);
            this.deleteProjectFromDb(project.ProjectCode);
            this.showDeleteProjectPrompt = false;
            this.showEditProject = false;
            this.projectForDeletion = {};
        },
        editProjectList({ newProject, project }) {
            if (newProject) {
                const newProjectGuid = 'TEMP' + crypto.randomUUID();
                const newLocationGuid = 'TEMP' + crypto.randomUUID();
                this.projectToEdit = {
                    ProjectCode: newProjectGuid,
                    ProjectDescription: window.vue.translations.label.label_new_project_name,
                    Locations: [{ 
                        LocationCode: newLocationGuid, 
                        LocationDescription: window.vue.translations.label.label_new_location_name,                        
                        Products: []
                    }],
                };
            } else {           
                var newProjectToEdit =  JSON.parse(JSON.stringify(project));
                this.projectToEdit = newProjectToEdit;
            }            
            this.showEditProject = true;
        },
        changeProjectCode(event) {
            this.searchText = '';
        },
        saveProject(newConfiguredProject) {
            this.savingProject = true;
            axios.post(this.bespokeEndpoints.projectListEndpoint + "/SaveNewProject", { Project: newConfiguredProject }).then(res => {
                this.getProjects().then(projRes => {
                    this.savingProject = false; 
                    this.openProjects = [];
                    let projectCode = newConfiguredProject.ProjectCode;
                    if (!this.openProjects.includes(projectCode)) {
                        this.openProjects.push(projectCode);  
                    } 
                    this.getProductInformation(newConfiguredProject);            
                })
            });
            this.savingProject = false; 
            this.showEditProject = false;  
        }
    }
}
</script>
