<template>
  <div class="cmp-elastic-product-filters utlz-reset col-md-12">

    <transition name="slide-fade">
      <div v-if="showMobileFilters" class="filters-container" :ref="'filters-container'">

        <a v-if="showMobileFilters" @click="toggleMobileFilters()" class="close-filters-btn show-mobile">
          <i class="fa fa-times"></i>
        </a>

        <div v-for="(filterGroup, i) in filters" :key="'filter-group-' + i" class="filters-wrapper">
          <div class="filter-header">
            <h2>{{ filterGroup.label }}</h2>
          </div>
          <div class="filter-content">
            <ul :ref="'filter-group-list' + i" :class="{ collapsed: filterGroup.value.length > 5 }">
              <li v-for="(filterObj, id) in filterGroup.value" :key="'filter-group-item-' + id">
                <div class="checkbox-group">
                  <input type="checkbox" :id="'checkbox-filter-' + filterGroup.id + '-' + filterObj.key" @change="checkedFilter($event, { id: filterGroup.id, value: filterObj.key, elemId: 'checkbox-filter-' + filterGroup.id + '-' + filterObj.key })" :checked="filterObj.active === true">
                  <label :for="'checkbox-filter-' + filterGroup.id + '-' +  filterObj.key">
                    <span class="key">
                      {{ filterObj.key }}
                    </span>
                    <!-- BESPOKE VLINT: Disable count since we use multiselect filters, these numbers are not correct -->
                    <!-- <span class="count">
                      ({{ filterObj.value }})
                    </span> -->
                    <!-- END BESPOKE VLINT -->
                  </label>
                </div>
              </li>
            </ul>
            <div v-if="filterGroup.value.length > 5" class="collapse-btns" @click="collapseList('filter-group-list' + i, filterGroup)">
              <div class="show-more-btn">
                <span class="text-link">
                  <i class="fa fa-angle-double-down"></i>
                  <span v-translation="{ type: 'label', code: 'label_filter_collapse_more' }"></span>
                </span>
                <span class="total-collapsed count">({{ filterGroup.value.length - 5 }})</span>
              </div>
              <div class="show-less-btn">
                <span class="text-link">
                  <i class="fa fa-angle-double-up"></i>
                  <span v-translation="{ type: 'label', code: 'label_filter_collapse_less'}"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapState('elastic', ['filters']),
    ...mapGetters('elastic', ['showMobileFilters'])
  },
  methods: {
    // BESPOKE VLINT
    // Added toggleFilters
    ...mapActions('elastic', ['updateFilters', 'toggleMobileFilters', 'toggleFiltersCollapse']),
    // END BESPOKE VLINT
    checkedFilter (ev, filter) {
      const payload = {
        filterActive: ev.target.checked,
        filterId: filter.id,
        filterValue: filter.value
      };
      if (ev.target.checked) {
        document.getElementById(filter.elemId).checked = false;
      } else {
        document.getElementById(filter.elemId).checked = true;
      }
      this.updateFilters(payload);
      // BESPOKE VLNT-7
      window.scroll({ top: 0 });
      // END BESPOKE VLNT-7
    },
    collapseList ($event, filterGroup) {
      this.$refs[$event][0].classList.toggle('collapsed');
      // BESPOKE VLINT
      // Once filters are not collapsed, resort the filters numerically or alphabetically instead of by most results
      var classes = this.$refs[$event][0].classList.value;
      var isCollapsed = classes == 'collapsed';
      this.toggleFiltersCollapse({ filterId: filterGroup.id, isCollapsed: isCollapsed });
      // END BESPOKE VLINT
    }
  }
}
</script>
<style>

</style>
